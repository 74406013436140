import React from 'react';
import './appBar.css'

function AppBar() {
    return (
        <div className='lp-app-bar'>
            <div className='lp-app-bar-content'>
                <div className='lp-app-bar-title'>Piyush Saklani</div>

                <div className='lp-links'>
                    <button className='lp-link-button'>Blogs</button>
                    <button className='lp-link-button'>Connect</button>
                </div>
            </div>
        </div>
    )
}

export default AppBar;