import React, { useState } from 'react';
import './landingPage.css';
import AppBar from '../../Components/appBar/appBar';
import Blog from '../../Components/blog/blog';
import blogs from '../../blogs.json';

function LandingPage() {

    const blogsData = blogs.blogs;

    return (
        <div className='landing-page'>
            <AppBar />
            {
                blogsData.map((blog, index) => {
                    return (
                        <Blog title={blog.title} description={blog.description} date={blog.date} keywords={blog.keywords} readtime={blog.readtime} page={blog.page} key={index} />
                    )
                })
            }
        </div>
    );
}

export default LandingPage;
