import React from 'react';
import './blog.css';
import { Link } from 'react-router-dom';

function Blog({ title, description, date, keywords = [], readtime = null, page = null}) {
    return (
        <div className='blogs-div'>
            <div className='blog-title'>{title}</div>
            <div className='blog-info'>
                <div style={{ width: '20px', height: '20px', marginRight: '3px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                    </svg>
                </div>
                <div>{date}</div>
                <div style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '3px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                </div>
                {readtime && <div>{readtime} read</div>}
            </div>
            <div className='blog-description'>{description}</div>
            <div className='blog-readme-button-div'><Link to={page}><button className='blog-readme-button'>Read more</button></Link></div>

            <div className='blogs-keyword-title'>Keywords and Key Topics</div>
            <div className='blogs-keywords'>
                {keywords.map((keyword, index) => {
                    return (
                        <div className='blog-keyword' key={index}>{keyword}</div>
                    )
                })}
            </div>

        </div>
    )
}

export default Blog;